






















































import { CALLABLE_FUNCTIONS } from "@/utils/callables";
import {
  EMAIL_VALIDATION_RULES,
  PASSWORD_VALIDATION_RULES,
  requiredFieldRule,
  ValidationRules
} from "@/utils/validation";
import { WatchLoading } from "@/decorators/Loading";
import { DelayMixin, LoadingMixin } from "@/mixins/Helpers";
import { httpsCallable } from "@firebase/functions";
import Component, { mixins } from "vue-class-component";
import { RegisterUserRequest, RegisterUserResponse } from "@sportango/backend";
import { Auth, Functions, resolveUserFromAuthState } from "@/firebase";
import { save } from "@/store/localStorage";
import {
  sendEmailVerification,
  signInWithEmailAndPassword
} from "@firebase/auth";
import { SportangoTextField } from "@/components/Inputs/overrides";

@Component({
  name: "register-coach",
  components: {
    SportangoTextField
  }
})
export default class RegisterCoach extends mixins(LoadingMixin, DelayMixin) {
  isValid = false;
  email = "";
  password = "";
  confirmPassword = "";
  errorMessage: string | null = null;
  readonly requiredFieldRule = requiredFieldRule;
  readonly EMAIL_VALIDATION_RULES = EMAIL_VALIDATION_RULES;
  readonly PASSWORD_VALIDATION_RULES = PASSWORD_VALIDATION_RULES;
  confirmPasswordRules: ValidationRules = [
    ...PASSWORD_VALIDATION_RULES,
    this.isSamePassword
  ];

  isSamePassword(v: string): true | string {
    return v === this.password || "Passwords do not match";
  }

  @WatchLoading()
  async register(): Promise<void> {
    const registerWithEmailAndPassword = httpsCallable<
      RegisterUserRequest,
      RegisterUserResponse
    >(Functions, CALLABLE_FUNCTIONS.registerWithEmailAndPassword);
    const response = await registerWithEmailAndPassword({
      email: this.email,
      password: this.password,
      roles: ["coach"]
    });
    if (response.data.isSuccess) {
      save("userEmail", this.email, "string");
      await signInWithEmailAndPassword(Auth, this.email, this.password);
      const user = await resolveUserFromAuthState();
      if (user) {
        await this.delay(15000);
        await sendEmailVerification(user);
      }
    } else {
      this.errorMessage = response.data.message || null;
    }
  }
}
